<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>
      <a-col :sm='24' :md='12' :xl='6' :style="{ marginBottom: '24px' }">
        <chart-card :loading='loading' title='商品总价' :total='"￥" + dashboard.totalPrice'>
          <a-tooltip title='商品总价' slot='action'>
            <a-icon type='info-circle-o' />
          </a-tooltip>
        </chart-card>
      </a-col>
      <a-col :sm='24' :md='12' :xl='6' :style="{ marginBottom: '24px' }">
        <chart-card :loading='loading' title='商品总数' :total='dashboard.totalCount'>
          <a-tooltip title='商品总数' slot='action'>
            <a-icon type='info-circle-o' />
          </a-tooltip>
        </chart-card>
      </a-col>
    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px;">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <template >
          <span >
            <span style="margin: 10px 10px;cursor: pointer;" @click="initTypeDetail('2')" > 订单出库：{{dashboard.orderOutCount!='0'?'-':''}}{{dashboard.orderOutCount}}</span>
            <span style="margin: 10px 10px;cursor: pointer;" @click="initTypeDetail('7')"> 业务赠送：{{dashboard.giveOutCount}}</span>
            <span style="margin: 10px 10px;cursor: pointer;" @click="initTypeDetail('8')"> 合创出库：{{dashboard.hechuangOutCount}}</span>
            <span style="margin: 10px 10px;cursor: pointer;" @click="initTypeDetail('9')"> 退换货：{{dashboard.returnOutCount}}</span>
            <span style="margin: 10px 10px;cursor: pointer;" @click="initTypeDetail('10')"> 门票出库：{{dashboard.ticketOutCount!='0'?'-':''}}{{dashboard.ticketOutCount}}</span>
            <span style="margin: 10px 10px;cursor: pointer;" @click="initTypeDetail('3')"> 04交接20：{{dashboard.exchangeOutCount}}</span>
          </span>
        </template>
        <a-button style="float: right;" type='primary' @click='initialDeliver1'>手动出库</a-button>

      </div>
      <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: "100%"}' :data='loadData'>
        <span slot='name' slot-scope='text,record'>
          <template>
            <a @click='handleEdit(record)'>
            <Ellipsis tooltip :length='20'> {{text}}</Ellipsis>
              </a>
          </template>
        </span>
        <span slot='specs' slot-scope='text'>
          <template>
            <Ellipsis tooltip :length='15'>{{ text }}</Ellipsis>
          </template>
        </span>
        <span slot='storageBalance' slot-scope='text'>
          <template>
            <span v-if="text >= 1000">{{text}}</span>
            <span v-else style="color: red;">{{text}}</span>
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
<!--              <a @click='handleEdit(record)'>出入库记录</a>-->
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal title='手动出库' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='商品' prop='production'>
          <a-select v-model='deliver1.form.production'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    placeholder="请选择"
                    @change="handleChange1"
                    @search='handleSearch1'>
            <a-select-option v-for='item in prodList' :key='item.depositoryProduction'>
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='数量' prop='storageCount' >
          <a-input-number v-model='deliver1.form.storageCount' style="width: 100%;"/>
        </a-form-model-item>

        <a-form-model-item label='类型' prop='type'>
          <a-select v-model="deliver1.form.type" placeholder="类型" >
            <a-select-option :value="7">
              业务赠送
            </a-select-option>
            <a-select-option :value="8">
              合创出库
            </a-select-option>
            <a-select-option :value="9">
              退换货
            </a-select-option>
            <a-select-option :value="10">
              门票出库
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="交接时间" prop='storageTim'>
          <a-date-picker :valueFormat="createFormatType" v-model="deliver1.form.storageTim" showTime placeholder="选择日期时间" style="width: 100%;"/>
        </a-form-model-item>
        <a-form-model-item label='备注' prop='remark'>
          <a-input v-model='deliver1.form.remark' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { queryInsideStorehouseProd, deleteInsideStorehouseProd, putInsideProdOut, getChildDashboard } from '@/api/inside'
import moment from 'moment'

const columns = [
  {
    width: 200,
    title: '商品名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    width: 100,
    title: '20签收',
    dataIndex: 'exchangeCount'
  },
  {
    width: 100,
    title: '已出数量',
    dataIndex: 'outCount',
    scopedSlots: { customRender: 'outCount' }
  },
  {
    width: 100,
    title: '订单待出',
    dataIndex: 'waitOutCount',
    scopedSlots: { customRender: 'waitOutCount' }
  },
  {
    width: 100,
    title: '20剩余',
    dataIndex: 'storageBalance',
    scopedSlots: { customRender: 'storageBalance' }
  },
  {
    width: 100,
    title: '商品价格',
    dataIndex: 'price',
    scopedSlots: { customRender: 'price' }
  },
  {
    width: 200,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

const createFormatType = 'YYYY-MM-DD HH:mm:ss';

export default {
  name: 'InsideStorehouseChildList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      createFormatType,
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        start: baseStart,
        end: baseEnd,
      },
      //统计数据
      loading: true,
      dashboard: {},
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: { isDefault: 1 },//0主仓库，1子仓库
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        parameter.pageMode = 0;
        return queryInsideStorehouseProd(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            if (parameter.pageNum == 1) {
              this.getDashboard();
            }
            return datum
          })
      },
      prodList: [],
      deliver1: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {          
          production: [{ required: true, message: '请选择', trigger: 'change' }],
          storageCount: [{ required: true, message: '请输入', trigger: 'change' }],
          storageTim: [{ required: true, message: '请选择', trigger: 'change' }],
        },
      },
    }
  },
  created() {
  },
  methods: {
    moment,
    getDashboard() {
      getChildDashboard(Object.assign({}, this.queryParam, this.form)).then(result => {
        this.dashboard = result;
        this.loading = false;
        // debugger;
      })
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleChange1(e) {
      console.log(e);
      this.$set(this.deliver1.form, "production", e)
    },
    handleSearch1() {
      queryInsideStorehouseProd({ pageNum: 1, pageSize: 9999, pageMode: 0, isDefault: 1 }).then(result => {
        this.prodList = result.records;
      })
    },
    initialDeliver1() {
      this.handleSearch1()
      this.deliver1.form = {storageTim: moment().format(this.createFormatType)};
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        console.log(result)
        if (result) {
          this.deliver1.loading = true;
          putInsideProdOut(this.deliver1.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    handleEdit(record) {      
      this.$router.push({ path: '/inside/child/edit', query: { depositoryProduction: record.depositoryProduction }})
    },
    handleDelete(record) {
      deleteInsideStorehouseProd({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    initTypeDetail(typeId){
      // debugger;
      this.$router.push("/inside/child/type?type="+typeId);
    }
  }
}
</script>
